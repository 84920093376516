import { ActionReducerMap } from "@ngrx/store";
import { registrationReducer } from "./registration.reducer";

import { State } from '../app.state';


export const reducers: ActionReducerMap<State> = {
    registrationState: registrationReducer,

};
