<div id="layout-wrapper">

    <div class="d-flex flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom box-shadow">
        <img src="assets/images/logo.svg" width="100" height="32" class="d-inline-block align-top">
    </div>

    <div class="main-content">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-sm-12 col-md-8 col-lg-7 col-xl-5 card mt-3">

                    <div class="d-flex flex-column mt-4 logo-title unselectable">
                        <img src="assets/images/logoH.svg" width="36" class="m-auto" />
                    </div>

                    <ng-container *ngIf="currentViewMode == viewModes.OTP">
                        <div class="width-100 row justify-content-center mt-3">
                            <app-otp-input-shared [otpType]="otpType.MerchantManagerOnboarding" [body]="null"
                                [type]="'single'" [confirmationText]="''" [hideHeader]="true"
                                [smallConfirmationText]="'To access Pay Later Application'" (onResendOTP)="sendOtp()"
                                (onNextClick)="getRequestDetails($event)"
                                [mobileNumber]="otpSentResponse?.mobileNumber">
                            </app-otp-input-shared>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="currentViewMode == viewModes.Details" [dir]="selectedDirection">

                        <div class="language-switcher" [ngClass]="{'row-rev':selectedDirection=='rtl'}">
                            <button class="lang-button" [class]="{'selected-button':selectedLanguage=='en'}"
                                (click)="selectLanguage('en')">
                                English
                            </button>
                            <button class="lang-button" [class]="{'selected-button':selectedLanguage=='ar'}"
                                (click)="selectLanguage('ar')">
                                العربية
                            </button>
                        </div>

                        <h1 class="instruction mx-2 unselectable" [ngClass]="{'label-rev':selectedDirection=='rtl'}">
                            {{'title' | onboardingTranslate : selectedLanguage }}
                        </h1>

                        <div class="width-100 row justify-content-center mt-3">
                            <div class="content-container" [ngClass]="{'row-rev':selectedDirection=='rtl'}">
                                <div class="heading"> {{'requestID' | onboardingTranslate : selectedLanguage }} </div>
                                @if (onboardingDetails?.recordID){
                                <div class="value requestid"> {{onboardingDetails?.recordID}} </div>
                                }
                                @else{
                                <div class="value requestid">N/A</div>
                                }
                            </div>
                            <div class="content-container" [ngClass]="{'row-rev':selectedDirection=='rtl'}">
                                <div class="heading"> {{'requestedLimit' | onboardingTranslate : selectedLanguage }}
                                </div>
                                @if (onboardingDetails?.assignedLimit){
                                <div class="value requestedLimit"> {{onboardingDetails?.assignedLimit | currency :'':'':
                                    '1.0-0'}} {{"SAR" | transloco}}</div>
                                }
                                @else{
                                <div class="value requestedLimit">N/A</div>
                                }
                            </div>
                            <div class="content-container" [ngClass]="{'row-rev':selectedDirection=='rtl'}">
                                <div class="heading">{{'cashierName' | onboardingTranslate : selectedLanguage }} </div>
                                @if (onboardingDetails?.cashier?.firstName || onboardingDetails?.cashier?.lastName){
                                <div class="value"> {{onboardingDetails?.cashier?.firstName}}
                                    {{onboardingDetails?.cashier?.lastName}} </div>
                                }
                                @else{
                                <div class="value">N/A</div>
                                }
                            </div>
                            <div class="content-container" [ngClass]="{'row-rev':selectedDirection=='rtl'}">
                                <div class="heading"> {{'cashierPhone' | onboardingTranslate : selectedLanguage }}
                                </div>
                                @if (onboardingDetails?.cashier?.mobileNumber){
                                <div class="value"> {{onboardingDetails?.cashier?.mobileNumber | appPhoneNumber}} </div>
                                }
                                @else{
                                <div class="value">N/A</div>
                                }
                            </div>
                            <div class="content-container" [ngClass]="{'row-rev':selectedDirection=='rtl'}">
                                <div class="heading"> {{'branchName' | onboardingTranslate : selectedLanguage }} </div>
                                @if (onboardingDetails?.cashier?.branchName){
                                <div class="value"> {{onboardingDetails?.cashier?.branchName}} </div>
                                }
                                @else{
                                <div class="value">N/A</div>
                                }
                            </div>
                            <div class="content-container" [ngClass]="{'row-rev':selectedDirection=='rtl'}">
                                <div class="heading"> {{'branchAddress' | onboardingTranslate : selectedLanguage }}
                                </div>
                                @if (onboardingDetails?.cashier?.branchAddress){
                                <div class="value"> {{onboardingDetails?.cashier?.branchAddress}} </div>
                                }
                                @else{
                                <div class="value">N/A</div>
                                }
                            </div>
                            <div class="content-container" [ngClass]="{'row-rev':selectedDirection=='rtl'}">
                                <div class="heading"> {{'requestedOn' | onboardingTranslate : selectedLanguage }} </div>
                                @if (onboardingDetails?.assignedLimitDate){
                                <div class="value"> {{onboardingDetails?.assignedLimitDate | date}} </div>
                                }
                                @else{
                                <div class="value">N/A</div>
                                }

                            </div>
                            <div class="content-container" [ngClass]="{'row-rev':selectedDirection=='rtl'}">
                                <div class="heading"> {{'status' | onboardingTranslate : selectedLanguage }} </div>
                                @if (onboardingDetails?.status){
                                <div class="value"> {{onboardingDetails?.status}} </div>
                                }
                                @else{
                                <div class="value">N/A</div>
                                }
                            </div>
                        </div>

                        <div class="width-100 row justify-content-center mt-3 mb-3">
                            <div class="information-text-container"
                                [ngClass]="{'right-aligned':selectedDirection=='rtl'}">
                                <span class="text">
                                    {{'disclaimerText' | onboardingTranslate : selectedLanguage }}
                                </span>
                            </div>
                        </div>

                        <div class="width-100 mb-2"
                            [ngClass]="{'row-rev':selectedDirection=='rtl','flex-con':selectedDirection=='rtl'}">
                            <input class="m-x-6 agreement-checkbox" type="checkbox" name="termsAndCondition"
                                id="termsAndCondition" [(ngModel)]="agreementCheckbox"
                                [ngClass]="{'margin-top-6':selectedDirection=='rtl'}">
                            <label for="termsAndCondition" class="agreement-checkbox-label">
                                {{'acceptanceCheckbox' | onboardingTranslate : selectedLanguage }} <span
                                    class="terms">{{'termsAndConditions' | onboardingTranslate : selectedLanguage
                                    }}</span></label>
                        </div>

                        <div class="row justify-content-start w-100 my-1 buttons-wrapper"
                            [ngClass]="{'row-rev':selectedDirection=='rtl'}">
                            <button class="col btn btn-primary" (click)="updateOnboardingRequest('Approved')"
                                [disabled]="!agreementCheckbox">
                                {{'approveButton' | onboardingTranslate : selectedLanguage }}
                            </button>
                            <button class="col btn btn-outline-primary" [disabled]="!agreementCheckbox"
                                (click)="updateOnboardingRequest('Declined')">
                                {{'declineButton' | onboardingTranslate : selectedLanguage }}
                            </button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="currentViewMode==viewModes.Approved">
                        <div class="info-view-container">
                            <ng-lottie [options]="approvedLottie" width="212px" height="212px"></ng-lottie>
                            <div class="mt-3 mb-3">
                                {{'approvedMessage' | onboardingTranslate : selectedLanguage }}
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="currentViewMode==viewModes.Declined">
                        <div class="info-view-container">
                            <ng-lottie [options]="declinedLottie" width="212px" height="212px"></ng-lottie>
                            <div class="mt-3 mb-3">
                                {{'declinedMessage' | onboardingTranslate : selectedLanguage }}
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="currentViewMode==viewModes.Invalid">
                        <div class="info-view-container">
                            <ng-lottie [options]="invalidLottie" width="212px" height="212px"></ng-lottie>
                            <div class="mt-3 mb-3">
                                {{'invalidMessage' | onboardingTranslate : selectedLanguage }}
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <footer class="footer"></footer>
</div>