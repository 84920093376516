import { Component, ViewEncapsulation, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { Menu, NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { AuthService } from '../../../shared/services/auth.service';
import { GrowthBookService } from '../../services/growthbook.service';
import { NgStyle, NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { FeatherIconsComponent } from '../feather-icons/feather-icons.component';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { DirectionalityService } from '@shared/services/directionality.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [RouterLink, FeatherIconsComponent, NgStyle, NgIf, NgFor, NgClass, RouterLinkActive, TranslocoPipe, AsyncPipe]
})
export class SidebarComponent implements OnInit, OnDestroy {

  public iconSidebar;
  public menuItems: Menu[];
  public url: any;
  public fileurl: any;

  // For Horizontal Menu
  public margin: any = 0;
  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = false;

  selectTranslationSubscription: Subscription;

  constructor(
    private router: Router, 
    public navServices: NavService,
    private _gbs: GrowthBookService,
    public layout: LayoutService,
    public directionalityService: DirectionalityService,
    private translocoService: TranslocoService
    ) {
    this.navServices.menuItemsToShow.subscribe(menuItems => {
      this.prepareAndSetMenuItems(menuItems);
      this.setup();
    });
  }

  ngOnInit(): void {
    this.selectTranslationSubscription = this.translocoService.selectTranslation().subscribe({
      next: (langObj) => {
        this.setup();
      }
    });
  }

  setup() {
    this.menuItems = this.translateMenuItemsRecursively(this.menuItems);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.menuItems.filter(items => {
          if (items.path === event.url) {
            this.setNavActive(items);
          }
          if (!items.children) { return false; }
          items.children.filter(subItems => {
            if (subItems.path === event.url) {
              this.setNavActive(subItems);
            }
            if (!subItems.children) { return false; }
            subItems.children.filter(subSubItems => {
              if (subSubItems.path === event.url) {
                this.setNavActive(subSubItems);
              }
            });
          });
        });
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth - 500;
  }

  prepareAndSetMenuItems(menuItems: Menu[]){
    this.menuItems = menuItems.filter(item => {
      if(!item.featureFlag) return item;
      if(item.featureFlag) {
        if(this._gbs.isOn(item.featureFlag)) return item; 
      }
    });

    this.menuItems = this.menuItems.map(item => {
      if (item.children && item.children.length) {
        item.children = item.children.filter(children => {
          if(!children.featureFlag) return children;
          if (children.featureFlag && this._gbs.isOn(children.featureFlag)) return children;
        })
      }
      return item;
    })
  }

  translateMenuItemsRecursively(menuItems: Menu[]): Menu[] {
    return menuItems.map((x: Menu) => {
      if(x.children){
        return {...x, title: this.translocoService.translate(x.translationKey), children: this.translateMenuItemsRecursively(x.children)};
      }
      return {...x, title: this.translocoService.translate(x.translationKey)}
    })
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) { return false; }
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }


  // For Horizontal Menu
  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.leftArrowNone = true;
      this.rightArrowNone = false;
    } else {
      this.margin += this.width;
      this.rightArrowNone = false;
    }
  }

  scrollToRight() {
    if (this.margin <= -3051) {
      this.margin = -3464;
      this.leftArrowNone = false;
      this.rightArrowNone = true;
    } else {
      this.margin += -this.width;
      this.leftArrowNone = false;
    }
  }

  ngOnDestroy(){
    this.selectTranslationSubscription?.unsubscribe();
  }

}
