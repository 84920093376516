import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../shared/services/auth.service';
import { NgIf, AsyncPipe, NgClass } from '@angular/common';
import { TranslocoPipe } from '@jsverse/transloco';
import { DirectionalityService } from '@shared/services/directionality.service';

@Component({
    selector: 'app-user-account',
    templateUrl: './user-account.component.html',
    styleUrls: ['./user-account.component.scss'],
    standalone: true,
    imports: [NgIf, AsyncPipe, TranslocoPipe, NgClass]
})
export class UserAccountComponent implements OnInit {

  constructor(public authService: AuthService, public directionalityService: DirectionalityService) { }

  ngOnInit(): void {
  }

}
