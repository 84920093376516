/**
 * TranslocoHttpLoader is a class that implements the TranslocoLoader interface.
 * It is responsible for loading translations from a JSON file using the fetch API.
 */
import { Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    getTranslation(langPath: string) {
        return fetch(`/assets/i18n/${langPath}.json`).then<Translation>((res) => res.json());
    }
}
