import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BaseHttpService } from './base.service';
import { IBANTransferReceipt } from '../models/api-response.model';

@Injectable({
    providedIn: 'root'
})
export class PaymentService extends BaseHttpService {

    private apiURL = `${environment.baseAPIUrl}/web/userentity/suppliers/v1`; 
    // private apiURL = `https://localhost:60857/v1`;

    IBANTransfer(body): Observable<IBANTransferReceipt> {
        return this.post(`${this.apiURL}/transfer-to-iban`, body);
    }

    CollectSalesmenSales(): Observable<string> {
        return this.post(`${this.apiURL}/supplier-aggregate-sales`);
    }
    transferToControlWallet(amount,controlWalletEntityId): Observable<any> {
        return this.post(`${this.apiURL}/transfer-to-control-wallet?amount=${amount}&controlWalletEntityId=${controlWalletEntityId}`);
    }
    getControlWalletEntityId(): Observable<number> {
        return this.get(`${this.apiURL}/control-wallet-entityId`);
    }


    requestForOtp(): Observable<any> {
        return this.post(`${this.apiURL}/internal-transfer/request-otp`, {});
    }

    verifyPIN(pin: string): Observable<any> {
        const body = { pin };
        return this.post(
          `${this.apiURL}/internal-transfer/request-otp`,
          JSON.stringify(body)
        );
      }

}
