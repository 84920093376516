import { Direction } from '@angular/cdk/bidi';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DirectionalityService {
    dirSubject: BehaviorSubject<Direction> = new BehaviorSubject<Direction>(null);
}
