import { Routes } from '@angular/router';

export const registrationRoutes: Routes = [
  {
    path: '',
    redirectTo: 'cr-number'
  },
  {
    path: 'cr-number',
    loadComponent: () => import('./cr-number/cr-number.component').then(m => m.CrNumberComponent),
  },
  {
    path: 'verify-owner',
    loadComponent: () => import('./verify-owner/verify-owner.component').then(m => m.VerifyOwnerComponent),
  },
  {
    path: 'activity',
    loadComponent: () => import('./activity/activity.component').then(m => m.ActivityComponent),
  },
  {
    path: 'company-details',
    loadComponent: () => import('./company-details/company-details.component').then(m => m.CompanyDetailsComponent),
  },
  {
    path: 'financial-details',
    loadComponent: () => import('./financial-details/financial-details.component').then(m => m.FinancialDetailsComponent),
  },
  {
    path: 'set-email',
    loadComponent: () => import('./set-email/set-email.component').then(m => m.SetEmailComponent),
  },
  {
    path: 'default-sales-person',
    loadComponent: () => import('./default-sales-person/default-sales-person.component').then(m => m.DefaultSalesPersonComponent),
  },
  {
    path: 'review-registration',
    loadComponent: () => import('./review-registration/review-registration.component').then(m => m.ReviewRegistrationComponent),
  },
  {
    path: 'verify-mobile',
    loadComponent: () => import('./verify-mobile/verify-mobile.component').then(m => m.VerifyMobileComponent),
  },
  {
    path: 'attachments',
    loadComponent: () => import('./attachments/attachments.component').then(m => m.AttachmentsComponent),
  },
  {
    path: 'successful-registration',
    loadComponent: () => import('./successful-registration/successful-registration.component').then(m => m.SuccessfulRegistrationComponent),
  },
  {
    path: 'pending-verification',
    loadComponent: () => import('./registration-pending-verificaiton/registration-pending-verificaiton.component').then(m => m.PendingVerificationComponent),
  }

];


export const getNextStepRoute = (res: string) => {
  switch (res) {
    case 'verifyowner':
      return 'registration/verify-owner'; 

    case 'halacategory':
      return 'registration/activity'; 

    case 'kycinfo':
      return 'registration/company-details'; 

    case 'setgoduseremail':
      return 'registration/set-email'; 

    case 'defaultsalesperson':
      return 'registration/default-sales-person'; 

    case 'verifymobileotp':
      return 'registration/review-registration';

    case 'attachments':
      return 'registration/attachments';

    case 'pendingapproval':
      return 'registration/pending-verification';

    case 'finalizeregistration':
      return 'registration/successful-registration';

    case 'done':
      return 'registration/successful-registration';

    default:
      return 'registration/'
  }
}