import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  public config = {
    settings: {
      layout_type: 'ltr',
      layout_version: 'default-sidebar',
      sidebar_type: 'default-sidebar',
      sidebar: {
        type: 'compact-wrapper',
        body_type: 'sidebar-icon'
      }
    },
    color: {
      primary_color: '#009877',
      secondary_color: '#FFD100'
    }
  }

  constructor() {
    if (this.config.settings.layout_type === 'rtl')
      document.getElementsByTagName('html')[0].setAttribute('dir', this.config.settings.layout_type);

    document.documentElement.style.setProperty('--theme-deafult', this.config.color.primary_color);
    document.documentElement.style.setProperty('--theme-secondary', this.config.color.secondary_color);
  }


}
