import { finalize } from "rxjs/operators";
import { HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

export function spinnerInterceptor(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
) {
  let activeRequests = 0;
  const _spinnerService = inject(NgxSpinnerService);
  if (activeRequests === 0) {
    _spinnerService.show();
  } else {
    return next(request);
  }

  activeRequests++;

  return next(request).pipe(
    finalize(() => {
      activeRequests--;
      if (activeRequests === 0) {
        _spinnerService.hide();
      }
    })
  );
}
