import { Routes } from '@angular/router';
import { MaintenanceComponent } from 'src/app/pages/maintenance/maintenance.component';

export const full: Routes = [
  {
    path: 'error-page',
    children: [
      {
        path: 'error-400',
        loadComponent: () => import('../../pages/error/error400/error400.component').then(m => m.Error400Component),
      },
      {
        path: 'error-401',
        loadComponent: () => import('../../pages/error/error401/error401.component').then(m => m.Error401Component),
      },
      {
        path: 'error-403',
        loadComponent: () => import('../../pages/error/error403/error403.component').then(m => m.Error403Component),
      },
      {
        path: 'error-404',
        loadComponent: () => import('../../pages/error/error404/error404.component').then(m => m.Error404Component),
      },
      {
        path: 'error-500',
        loadComponent: () => import('../../pages/error/error500/error500.component').then(m => m.Error500Component),
      },
      {
        path: 'error-503',
        loadComponent: () => import('../../pages/error/error503/error503.component').then(m => m.Error503Component),
      }
    ],
  },
  {
    path: 'maintenance',
    loadComponent: () => import('../../pages/maintenance/maintenance.component').then(m => m.MaintenanceComponent),
  },
  {
    path: 'auth-callback',
    component: MaintenanceComponent
  }
];
