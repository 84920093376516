<div [dir]="this.direction">
  <app-loader></app-loader>
  <app-tap-to-top></app-tap-to-top>
  <app-full-page-loader></app-full-page-loader>
  <router-outlet></router-outlet>
  
</div>

<ngx-spinner
  [dir]="this.direction"
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">{{"LOADING" | transloco}}</p>
</ngx-spinner>