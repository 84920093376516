export function FormatDate(date: Date) {
    const month = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();
    const formattedDate = `${year}-${month >= 10 ? month : `0${month}`}-${day >= 10 ? day : `0${day}`}`;
    return formattedDate;
}

export function blobToText(blob: Blob): Promise<string> {
    return new Promise(async (resolve, reject) => {
        try {
            const text = await blob.text();
            resolve(text);
        } catch (e) {
            console.error(e, 'Error reading blob');
            reject('Error reading blob');
        }
    });
}
