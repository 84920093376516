import { HttpParams } from '@angular/common/http';

export function getHttpParams(inputParams): HttpParams {
    let params = new HttpParams();
    if (inputParams) {
        for (const [key, value] of Object.entries(inputParams)) {
            if (value) {
                params = params.append(key, value as string);
            }
        }
    }
    return params;
}
