<!-- page-wrapper Start-->

<div>
  <div [ngClass]="layout.config.settings.layout_type" [class]="layout.config.settings.layout_version">
    <div class="page-wrapper" id="canvas-bookmark" [ngClass]="layout.config.settings.sidebar.type">
      <app-header [dir]="this.directionalityService.dirSubject | async"></app-header>
      <!-- Page Body Start-->
      <div class="page-body-wrapper" [ngClass]="layout.config.settings.sidebar.body_type">
        <div [dir]="this.directionalityService.dirSubject | async" class="sidebar-wrapper" [attr.sidebar-layout]="layout.config.settings.sidebar_type"
          [class.close_icon]="navServices.collapseSidebar">
          <app-sidebar></app-sidebar>
        </div>
        <div [dir]="this.directionalityService.dirSubject | async" class="page-body">
          <!-- <app-content-loader [type]="'36'"></app-content-loader> -->
          <main [@fadeInAnimation]="getRouterOutletState(o)">
              <router-outlet #o="outlet"></router-outlet>
          </main>
        </div>
        <!-- footer start-->
        <!-- <footer class="footer">
          <app-footer></app-footer>
        </footer> -->
        <!-- footer End-->
      </div>
      <!-- Page Body End-->
    </div>
  </div>
</div>


<!-- <app-customizer></app-customizer> -->
<!-- page-wrapper End-->