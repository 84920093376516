import { HttpContext, HttpContextToken, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { LocalStorageManager } from "@shared/utils/local-storage-manager";
import { AuthService } from "../services/auth.service";

const BYPASS_AUTH = new HttpContextToken<boolean>(() => false);

export function byPassAuthorization() {
  return new HttpContext().set(BYPASS_AUTH, true);
}

export const jwtInterceptor = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
  const authService = inject(AuthService);
  const localStorageManager = inject(LocalStorageManager);

  if (request.context.get(BYPASS_AUTH)) return next(request);

  request = request.clone({
    setHeaders: {
      Authorization: `Bearer ${authService._user.access_token}`,
      Accept: "application/json",
      "Accept-Language": localStorageManager.getData('selectedLanguage') || 'en',
    },
  });

  return next(request);
};
