import { CommonModule, DatePipe } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VerifyMobileOtpComponent } from '@shared/components/otp-input/otp-input.component';
import { PhoneNumberPipe } from '@shared/pipes/phone-number.pipe';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { ToastrService } from 'ngx-toastr';
import { OtpType } from 'src/app/components/sadad/sadad.models';
import { MerchantManagerOnboardingDetails } from '../models/MerchantManagerOnboardingDetails';
import { MerchantManagerOnboardingService } from './../merchant-manager-onboarding.service';
import { OnboardingTranslatePipe } from './../pipes/onboarding-translate.pipe';

export enum onboardingViewModes {
  OTP,
  Details,
  Approved,
  Declined,
  Invalid
}

@Component({
  selector: 'app-manager-onboarding-details',
  standalone: true,
  imports: [DatePipe, CommonModule, FormsModule, VerifyMobileOtpComponent, LottieComponent, PhoneNumberPipe, OnboardingTranslatePipe],
  providers: [NgbActiveModal],
  templateUrl: './manager-onboarding-details.component.html',
  styleUrl: './manager-onboarding-details.component.scss'
})
export class ManagerOnboardingDetailsComponent implements OnInit {

  viewModes = onboardingViewModes;
  selectedLanguage: string = 'ar';
  selectedDirection: string = 'rtl';
  currentViewMode = this.viewModes.OTP;
  modal: any;

  _modalService = inject(NgbModal);
  _onboardingService = inject(MerchantManagerOnboardingService);
  activatedRoute = inject(ActivatedRoute);
  toastrService = inject(ToastrService);
  destroyRef = inject(DestroyRef);

  token: string;
  requestId: string;
  onboardingDetails: MerchantManagerOnboardingDetails;

  agreementCheckbox: boolean;
  otpType = OtpType;
  otpSentResponse;

  invalidLottie: AnimationOptions = {
    path: 'assets/lottie-animations/invalid_link.json',
  };

  declinedLottie: AnimationOptions = {
    path: 'assets/lottie-animations/rejected.json',
  };

  approvedLottie: AnimationOptions = {
    path: 'assets/lottie-animations/Done_Animation.json',
  };

  ngOnInit(): void {
    this.requestId = this.activatedRoute.snapshot.params['id'];
    this.activatedRoute.data
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(data => {
        this.token = data.token;
        this.sendOtp();
      });
  }

  getRequestDetails(otp: number): void {
    this._onboardingService.getOnboardingDetails(this.requestId, this.token, otp)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(res => {
        this.onboardingDetails = res;
        if (this.onboardingDetails.status != 'Pending Approval') {
          this.currentViewMode = this.viewModes.Invalid;
        }
        else {
          this.currentViewMode = this.viewModes.Details;
        }
      }, err => {
        if (err?.error?.error?.isValidOTP) {
          this.currentViewMode = this.viewModes.Invalid;
        }
      })
  }

  selectLanguage(lang: string): void {
    this.selectedLanguage = lang;
    if (this.selectedLanguage == 'en') {
      this.selectedDirection = 'ltr';
    }
    else {
      this.selectedDirection = 'rtl';
    }
    console.log(this.selectedDirection);
  }

  sendOtp(): void {
    this._onboardingService.sendOTP(this.requestId, this.token)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(res => {
        this.otpSentResponse = res;
      }, () => {
        this.currentViewMode = this.viewModes.Invalid;
      });
  }

  updateOnboardingRequest(status: string): void {
    this._onboardingService.updateOnboardingRequest(this.requestId, status, this.token, this.onboardingDetails?.cashierID)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (status === 'Approved') {
          this.currentViewMode = this.viewModes.Approved;
        } else if (status === 'Declined') {
          this.currentViewMode = this.viewModes.Declined;
        }
      });
  }

  formatNumberForOtp(number: string): string {
    return 'x'.repeat(number.length - 3) + number.slice(-3);
  }
}
