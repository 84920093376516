export interface PaymentType {
  code: string;
  label: string;
  isReversalAllowed: boolean;
}

export interface BillerService {
  billerCode: string;
  description: string;
  iconUrl: string;
  allowPartialPayment?: boolean;
  minimumPartialPayment?: number;
  paymentTypes?: PaymentType[];
  serviceCode?: string;
}

export interface BillerCategory {
  id: string;
  description: string;
  services: BillerService[];
}

export interface BillersResponseDto {
  categories: BillerCategory[];
}

export interface BillDetails {
  currentAmount: string;
  amountDue: string;
  billingAccountId: string;
  dueDate: string;
  serviceType: string;
  billerCode: string;
  displayLabel?: any
}

export interface BillDetailsKeyValue {
  title: string;
  value: string;
}

export interface BillDetailsDynamic {
  billerCode: string;
  billerLabel: string;
  billingAccountId:string;
  iconUrl: string;
  totalAmount: string;
  details : BillDetailsKeyValue []
}

export interface Field {
  reference: string;
  label: string;
  hint: string;
  dataType: number;
  dropdownOptions?: DropDownOption[];
  regex: string;
  isRequired: boolean;
  useInRefund: boolean;
}

export interface DropDownOption {
  reference: string;
  label: string;
  additionalFields: Field[];
}

export interface MOIFieldsResponseDto {
  fields: Field[];
}

export interface BillingDetail{
  title: string;
  value: string
}

export interface BillingAccount {
  billerCode: string;
  billingAccountId: string;
  label: string;
  amount: string;
  iconUrl: string;
  alias: string;
  dueDate: string;
  paymentType: PaymentType;
  allowPartialPayment: boolean;
  amountToPay?: number;
  billingStatus: number;
  details: BillingDetail[];
  isExpired: boolean;
  isSelected?: boolean
}
export interface PrepaidAccount {
  billerCode: string;
  billingAccountId: string;
  label: string;
  iconUrl: string;
  alias: string;
  paymentType: PaymentType;
}

export interface EntityProfile {
  pageNumber: number;
  pageSize: number;
  firstPage: string;
  lastPage: string;
  totalPages: number;
  totalRecords:number;
  nextPage: string;
  previousPage: string;
  data: BillingAccount[];
}

export interface MOIFeeInquiryResponseDto {
  groupPayId: number;
  sadadTrxNum: number;
  totalFeeAmount: string;
}


export interface SadadBillViewModel {
  isMOIPayment: boolean;

  // common
  amount: string;
  billNumber: number;
  biller: BillerService;

  // moi
  groupPayId?: string;
  transactionType?: string;
  inquiryInfo?: {},

  //sadad
  dueDate?: string;
  paymentTypeLabel?: string;
  paymentTypeCode?: string;
  balance?: string
}

export interface SadadBillViewModelDynamic {
  isMOIPayment: boolean;

  // common
  amount: string;
  billNumber: number;
  biller: BillerService;

  // moi
  groupPayId?: string;
  transactionType?: string;
  inquiryInfo?: {},

  //sadad
  details?: BillDetailsKeyValue [];
  paymentTypeLabel?: string;
  paymentTypeCode?: string;
  balance?: string
}

export interface SadadRefundViewModel {
  billNumber?: number;
  biller: BillerService;
  referenceId: string;
}


export interface MoiRefundResponseDTO {
  refundId: string,
  billerId: string,
  benfId: string,
  benfIdType: string,
  refundStatusCode: string,
  serviceCode: string,
  message: string
}

export interface Balance{
  data: number,
  message: string,
  status: number
}
export interface Amount {
  net: number;
  fees: number;
  vat: number;
  gross: number;
  hold: number;

  // computed properties
  displayAmount: string;
  displayClass: string;
}

export interface Status {
  ref: string;
  text: string;
  color: string;
}

export interface Brief {
  text: string;
  shortOut: string;
  shortIn: string;
  iconUrl: string;
  iconUrlDark: string;
}

export interface Card {
  type: string;
  number: string;
  network: string;
  authCode: string;
  logo: string;
  logoDark: string;
  rrn: string;
}

export interface Terminal {
  terminalId: string;
  externalId: string;
}

export interface Merchant {
  name: string;
  logo: string;
  logoDark: string;
}

export interface Category {
  name: string;
  icon: string;
  iconDark: string;
}

export interface Entity {
  firstName: string;
  firstNameMasked: string;
  lastName: string;
  lastNameMasked: string;
  photo: string;
  photoDark: string;
  mobileNumber: string;
  mobileNumberMasked: string;
  qrKey: string;
}

export interface Viban {
  iban: string;
  bankName: string;
  bankLogo: string;
  bankLogoDark: string;
  senderName: string;
}
export interface Beneficiary {
  beneficiaryId: string;
  name: string;
  iban: string;
  bankName: string;
  bankLogo: string;
  bankLogoDark: string;
  purposeText: string;
  isSarie: boolean;
  reason: string;
}

export interface Transaction {
  id: number;
  ref: string;
  dateTime: Date;
  typeRef: string;
  typeText: string;
  balance: number;
  channel: string;
  amount: Amount;
  status: Status;
  brief: Brief;
  card?: Card;
  terminal?: Terminal;
  horizontalTextFields: any[];
  horizontalTwoTextFields: any[];
  verticalTextFields: any[];
  merchant?: Merchant;
  category?: Category;
  memo?: string;
  attachment?: string;
  attachments?: string[];
  entity?: Entity;
  viban?: Viban;
  beneficiary?: Beneficiary;
  // computed properties
  image: string;
  entityDisplayName: string;
}

export function setTransactionViewModel(transaction: Transaction): Transaction {
  let image = '';
  let entityDisplayName = transaction.brief.text;

  if (transaction.entity) {
    const entity = transaction.entity;
    if (entity.photo)
      image = entity.photo;

    if (entity.firstNameMasked && entity.lastNameMasked)
      entityDisplayName = `${entity.firstNameMasked} ${entity.lastNameMasked}`;
    else
      entityDisplayName = entity.mobileNumber;
  }
  else if (transaction.merchant) {
    image = transaction.merchant.logo;
    entityDisplayName = transaction.merchant.name;
  }
  else if (transaction.viban) {
    image = transaction.viban.bankLogo;
    entityDisplayName = transaction.viban.bankName;
  }
  else if (transaction.beneficiary) {
    image = transaction.beneficiary.bankLogo;
    entityDisplayName = transaction.beneficiary.bankName;
  }
  else if (transaction.card) {
    image = transaction.card.logo;
    entityDisplayName = `${transaction.card.network} ${transaction.card.number}`;
  }

  if (!image) image = transaction.brief?.iconUrl;

  transaction.image = image;
  transaction.entityDisplayName = entityDisplayName;

  return transaction;

}

export interface BillingFilter {
  pageNumber: number;
  pageSize: number
}

export enum OtpType {
  SadadConfirmation,
  SadadMOIConfirmation,
  MerchantManagerOnboarding
}


