import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadComponent: () => import('../../components/dashboard/dashboard/dashboard.component').then(m => m.DashboardComponent),
  },
  {
    path: 'beneficiaries',
    loadComponent: () => import('../../components/beneficiaries/list-beneficiaries/list-beneficiaries.component').then(m => m.BeneficiariesListComponent),
  },
  
  {
    path: 'tax-invoice',
    loadComponent: () => import('../../components/tax-invoice/tax-invoice.component')
    .then(m => m.TaxInvoiceComponent),
    data: {
      title: 'Tax Invoice'
    }
  },
  {
    path: 'company-report',
    children: [
      {
        path: '',
        loadComponent: () => import('../../components/reports/company-report/company-report.component').then(m => m.CompanyReportComponent),
        data: {
          title: 'Company Report'
        }
      },
      {
        path: 'salesmen',
        loadComponent: () => import('../../components/reports/salesmen-report/salesmen-report.component').then(m => m.SalesmenReportComponent),
        data: {
          title: 'Salesmen Report'
        }
      },
    ],
  },
  {
    path: 'account-statement',
    loadComponent: () => import('../../components/statements/account-statement/account-statement.component').then(m => m.AccountStatementComponent)
  },
  {
    path: 'sales-groups',
    children: [
      {
        path: 'create',
        loadComponent: () => import('../../components/sales-groups/create-salesgroup/create-sales-group.component').then(m => m.CreateSalesGroupComponent),
        data: {
          title: 'Create sales group'
        }
      },
      {
        path: 'salesmen-management',
        loadComponent: () => import('../../components/sales-groups/salesmen-management/salesmen-management.component').then(m => m.SalesmenManagementComponent),
        data: {
          title: 'Salesmen management'
        }
      },
      {
        path: 'sales-groups-management',
        loadComponent: () => import('../../components/sales-groups/sales-groups-management/sales-groups-management.component').then(m => m.SalesGroupsManagementComponent),
        data: {
          title: 'Sales groups management'
        }
      }
    ]
  },
  {
    path: 'users-management',
    children: [
      {
        path: 'create-salesmen-accounts',
        loadComponent: () => import('../../components/users-management/create-salesmen-accounts/create-salesmen-accounts.component').then(m => m.CreateSalesmenAccountsComponent),
        data: {
          title: 'Create salesmen accounts'
        }
      },
      {
        path: 'create-supervisor',
        loadComponent: () => import('../../components/users-management/create-supervisor/create-supervisor.component').then(m => m.CreateSupervisorComponent),
        data: {
          title: 'Create supervisor'
        }
      },
      {
        path: 'supervisors',
        loadComponent: () => import('../../components/users-management/supervisors/supervisors.component').then(m => m.SupervisorsComponent),
        data: {
          title: 'Supervisors'
        }
      }
    ]
  },
  {
    path: 'external-id-management',
    loadComponent: () => import('../../components/external-id-management/external-id-creation/external-id-creation.component').then(m => m.ExternalIdCreationComponent),
  },
  {
    path: 'cashout-requests',
    children: [
      {
        path: '',
        loadComponent: () => import('../../components/cashout-requests/view-requests/view-requests.component').then(m => m.ViewRequestsComponent),
        data: {
          title: 'view requests'
        }
      },
      {
        path: 'create',
        loadComponent: () => import('../../components/cashout-requests/create-cashout-request/create-cashout-request.component').then(m => m.CreateCashOutRequestComponent),
        data: {
          title: 'Create cash out request'
        }
      },
      {
        path: 'settings',
        loadComponent: () => import('../../components/cashout-requests/request-settings/request-settings.component').then(m => m.RequestSettings),
        data: {
          title: 'Request Settings'
        }
      },
    ]
  },
  {
    path: 'sadad',
    children: [
      {
        path: '',
        loadComponent: () => import('../../components/sadad/sadad-home/sadad-home.component').then(m => m.SadadHomeComponent),
        data: {
          titleAr: 'SADAD'
        }
      },
      {
        path: 'add-bill',
        loadComponent: () => import('../../components/sadad/add-bill/add-bill.component').then(m => m.AddBillComponent),
        data: {
          titleAr: 'SADAD'
        }
      },
      {
        path: 'one-time',
        loadComponent: () => import('../../components/sadad/sadad-billers/sadad-billers.component').then(m => m.SadadBillersComponent),
        data: {
          titleAr: 'SADAD'
        }
      },
      {
        path: 'government-payments',
        loadComponent: () => import('../../components/sadad/moi-billers/moi-billers.component').then(m => m.MoiBillersComponent),
        data: {
          titleAr: 'SADAD'
        }
      }
    ]
  },
  {
    path: 's2s-requests',
    loadComponent: () => import('../../components/s2s-requests/s2s-request-list/s2s-request-list.component').then(m => m.S2sRequestListComponent),
  }
];
