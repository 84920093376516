import { updateRegistrationHash, updateKyc } from './../actions/registration.actions';
import { createReducer, on } from '@ngrx/store';
import {
    updateCcToken,
    updateCr,
    updateId,
    updateMobile,
    updateDob,
    updateMainCategory,
    updateSubCategory,
    updateEmail,
    updateSalesPerson,
    updateAttachments,
    reset
} from '../actions/registration.actions';
import { RegistrationState } from '../models';

const initialState: RegistrationState = {
    ccToken: '',
    cr: '',
    ownerNationalId: '',
    mobile: '',
    dob: null,
    mainCategoryId: null,
    subCategoryId: null,
    email: '',
    salesPerson: {
        id: '',
        mobile: '',
        dob: '',
        externalId: '',
        language: 0
    },
    attachments: {
        crNumber: '',
        companyCR: '',
        companyAOA: '',
        managerID: '',
        delegationLetter: '',
        companyOwnersIDs: []
    },
    kycInfo: {
        numberOfBranchesLookupDescriptionAr: '',
        numberOfBranchesLookupDescriptionEn: '',
        numberOfEmployeesLookupDescriptionAr: '',
        numberOfEmployeesLookupDescriptionEn: '',
        purposeOfOpeningWalletLookupDescriptionAr: '',
        purposeOfOpeningWalletLookupDescriptionEn: '',
        yearlyRevenueRangeDescriptionAr: '',
        yearlyRevenueRangeDescriptionEn: ''
    }
};

export const registrationReducer = createReducer(
    initialState,
    on(updateCcToken, (state, { ccToken }) => { return { ...state, ccToken } }),
    on(updateMobile, (state, { mobile }) => { return { ...state, mobile } }),
    on(updateCr, (state, { cr }) => { return { ...state, cr } }),
    on(updateId, (state, { id }) => { return { ...state, ownerNationalId: id } }),
    on(updateSubCategory, (state, { subCategory }) => { return { ...state, subCategoryId: subCategory } }),
    on(updateMainCategory, (state, { category }) => { return { ...state, mainCategoryId: category } }),
    on(updateDob, (state, { dob }) => { return { ...state, dob } }),
    on(updateEmail, (state, { email }) => { return { ...state, email } }),
    on(updateSalesPerson, (state, { salesPerson }) => { return { ...state, salesPerson } }),
    on(updateAttachments, (state, { attachments }) => { return { ...state, attachments } }),
    on(updateRegistrationHash, (state, { hash }) => { return { ...state, hash } }),
    on(updateKyc, (state, { kycInfo }) => { return { ...state, kycInfo: kycInfo } }),
    on(reset, () => initialState)
);


