<div [dir]="this.directionalityService.dirSubject | async">
    <div *ngIf="!hideHeader" class="modal-header !border-0 title">
        <div class="font-semibold text-[28px]" id="modal-basic-title">{{title}}</div>
        <img src="/assets/icons/close-icon.svg" alt="" class="img-fluid cursor-pointer" (click)="activeModal.close()">
    </div>
    <div class="modal-body">
        <div class="flex flex-col items-center justify-center">
            <img  src="/assets/icons/otp-icon.svg" alt="" class="img-fluid h-[126px] w-[106px]">
            <div class="text-[17px] font-normal mt-2">{{confirmationText}}</div>
            <div class="text-[13px] font-normal text-center mt-2" *ngIf="smallConfirmationText">{{smallConfirmationText}}</div>
            <div class="text-[13px] font-normal text-center mt-2">{{"ENTER_OTP_SENT_TO_YOUR_MOBILE_NUMBER" | transloco}}</div>
            <div class="text-[13px] font-normal text-center" *ngIf="!!mobileNumber">+966{{mobileNumber}}</div>
        </div>
            <form  [formGroup]="form" class="flex flex-col gap-4 items-center my-4">
                <div id="pin-inputs" class="flex items-center gap-4 h-[61px] p-4 border rounded !border-black">
                    <input inputmode="numeric" autofocus type="text" class="text-center appearance-none border-b border-t-0 border-l-0 border-r-0 w-[41.5px]"
                        autocomplete="off" formControlName="otp1" maxlength="1" mask="0"
                        (keyup)="nextInput($event, 1, 'otp2')">
                    <input inputmode="numeric" type="text" class="text-center appearance-none border-b border-t-0 border-l-0 border-r-0 w-[41.5px]" id="otp2"
                        formControlName="otp2" maxlength="1" mask="0" autocomplete="off"
                        (keyup)="prevInput($event, 'otp1'); nextInput($event, 1, 'otp3')">
                    <input inputmode="numeric" type="text" class="text-center appearance-none border-b border-t-0 border-l-0 border-r-0 w-[41.5px]" id="otp3"
                        formControlName="otp3" maxlength="1" mask="0" autocomplete="off"
                        (keyup)="prevInput($event, 'otp2'); nextInput($event, 1, 'otp4')">
                    <input inputmode="numeric" type="text" class="text-center appearance-none border-b border-t-0 border-l-0 border-r-0 w-[41.5px]" id="otp4"
                        formControlName="otp4" maxlength="1" mask="0" autocomplete="off"
                        (keyup)="prevInput($event, 'otp3'); nextInput($event, 1, 'submit')">
                </div>
            </form>
        <div class="timer-main text-center">
            <div class="m-auto" *ngIf="showResendButton; else timer">
                <span>{{"DIDNT_RECEIVE_CODE" | transloco}} </span><span class="resend" (click)="onResend()">{{"RESEND_SMS" | transloco}}</span>
            </div>
            <ng-template #timer>
                <div class="fs-16 pb-0 m-auto mt-3 remaining-time">{{"TIME_REMAINING" | transloco}} {{remainingTime}}</div>
            </ng-template>
        </div>
        <div class="mt-6 flex justify-center cursor-pointer rounded-lg">
            <button class="btn !bg-[#009877]  w-[345px] h-[50px]" (click)="submit()" [disabled]="!form.valid">{{"NEXT" | transloco}}</button>
        </div>
    </div>
</div>

