import { CcTokenService } from "./../shared/services/cc-token.service";
import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn } from "@angular/router";
import { Observable } from "rxjs";

export const tokenResolver: ResolveFn<string> = (
  route: ActivatedRouteSnapshot
): Observable<any> => {
  const tokenService = inject(CcTokenService);
  return tokenService.getCcToken();
};
