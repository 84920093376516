import { Injectable } from '@angular/core';
import { GrowthBook } from '@growthbook/growthbook';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class GrowthBookService {
    private growthBook: GrowthBook;
    constructor() { }

    async loadGrowthBook(email: string) {
        this.growthBook = new GrowthBook({
            apiHost: 'https://cdn.growthbook.io',
            clientKey: environment.growthbookAPIKey,
            enableDevMode: true,
            subscribeToChanges: true,
            attributes: {
                'Email': email
            }
        });

        await this.loadFeatures();
    }

    async loadFeatures(): Promise<void> {
        return await this.growthBook.loadFeatures({ autoRefresh: true });
    }

    isOn(featureKey: string): boolean {
        console.log(this.growthBook.isOn(featureKey));
        return this.growthBook.isOn(featureKey);
    }

}
