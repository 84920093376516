import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentService } from '@shared/services/payment.service';
import * as dayjs from 'dayjs';
import { ToastrService } from 'ngx-toastr';
import { timer } from 'rxjs';
import { OtpType, SadadBillViewModelDynamic } from 'src/app/components/sadad/sadad.models';
import { SadadService } from 'src/app/components/sadad/sadad.service';
import { NgxMaskDirective } from 'ngx-mask';
import { AsyncPipe, NgIf } from '@angular/common';
import { MerchantManagerOnboardingService } from 'src/app/merchant-manager-onboarding/merchant-manager-onboarding.service';
import { TranslocoPipe } from '@jsverse/transloco';
import { DirectionalityService } from '@shared/services/directionality.service';

@Component({
  selector: 'app-otp-input-shared',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule, NgxMaskDirective, AsyncPipe, TranslocoPipe]
})
export class VerifyMobileOtpComponent implements OnInit {
  @Input() mobileNumber: string = '';
  @Input() title: string = 'Confirmation';
  @Input() confirmationText: string = 'Bill Payment Confirmation';
  @Input() smallConfirmationText: string;
  @Input() otpType!: OtpType;
  @Input() body: any;
  @Input() type: string = 'single';
  @Input() data: any;
  @Input() hideHeader: boolean = false;
  @Output() onResendOTP = new EventEmitter();
  @Output() onNextClick = new EventEmitter();
  showResendButton = false;
  remainingTime: any;
  form: any;
  timer$: any;
  constructor(
    private _formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private paymentService: PaymentService,
    private toastrService: ToastrService,
    private sadadService: SadadService,
    private onboardingService: MerchantManagerOnboardingService,
    public directionalityService: DirectionalityService
  ) { }

  ngOnInit(): void {
    this.initializeForm();
    const inputs = document.getElementById('pin-inputs') as HTMLDivElement;
    this.handleKeyChanges(inputs);
    this.startTimer();
  }

  initializeForm = () => {
    this.form = this._formBuilder.group({
      otp1: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
      otp2: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
      otp3: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
      otp4: new UntypedFormControl('', [Validators.required, Validators.minLength(1)])
    });
  }

  private handleKeyChanges(form: HTMLDivElement): void {
    let currentVal;
    form.addEventListener('input', (e: Event) => {
      const target = e.target as HTMLInputElement;
      const val = target.value;

      if (isNaN(Number(val))) {
        target.value = '';
        return;
      }

      target.value = target.value.replace(/./g, '•');
      currentVal = target.value;
      if (target.value !== '') {
        const next = target.nextElementSibling as HTMLInputElement | null;
        if (next) {
          next.focus();
        }
      }
    });

    form.addEventListener('keyup', (e: KeyboardEvent) => {
      const target = e.target as HTMLInputElement;
      const key = e.key.toLowerCase();
      if (key === 'backspace' || key === 'delete') {
        const value = target.value;
        target.value = '';
        if (!value) {
          const prev = target.previousElementSibling as HTMLInputElement | null;
          if (prev) {
            prev.focus();
          }
          return;
        }
      }
    });
  }

  startTimer() {
    const stopTime = dayjs().add(0.5, 'minutes')
    this.timer$ = timer(0, 1000).subscribe(() => this.updateTime(stopTime))
  }

  updateTime(stopTime: any) {
    const now = dayjs();
    const timerIsDone = now.isAfter(stopTime)
    if (timerIsDone) {
      this.showResendButton = true;
      this.timer$.unsubscribe();
      return
    }
    this.remainingTime = dayjs(stopTime.valueOf() - now.valueOf()).format('m:ss');
  }

  nextInput(event: any, max: number, next: string) {
    if (event.target.value.length == max) {
      event.target.blur();
      document.getElementById(next)?.focus();
    }
  }

  prevInput(event: any, prev: string) {
    if (event.target.value.length == 0 && event.code == 'Backspace')
      document.getElementById(prev)?.focus();
  }

  onResend() {
    if (this.type === 'single') {
      switch (this.otpType) {
        case OtpType.SadadConfirmation:
          this.resendSadadOtp();
          break;
        case OtpType.SadadMOIConfirmation:
          this.resenMoiOtp();
          break;
        case OtpType.MerchantManagerOnboarding:
          this.onResendOTP.emit();
          this.showResendButton = false;
          this.startTimer();
          break;
        default:
          this.activeModal.close();
      }
    }
    else {
      this.onResendOTP.emit();
    }

  }

  submit = () => {
    const pin =
      this.form.value.otp1 +
      this.form.value.otp2 +
      this.form.value.otp3 +
      this.form.value.otp4;

    if (!this.form.valid) {
      return;
    }
    if (pin.length === 4) {
      switch (this.otpType) {
        case OtpType.SadadConfirmation:
          this.confirmSadadPayment(pin);
          break;
        case OtpType.SadadMOIConfirmation:
          this.confirmSadadMOIPayment(pin);
          break;
        case OtpType.MerchantManagerOnboarding:
          this.onNextClick.emit(pin)
          break;
        default:
          this.activeModal.close();
      }
    };
  }

  resendSadadOtp = () => {
    this.sadadService.createSadadPayment(this.data.viewModel.biller.billerCode, this.data.viewModel.billNumber.toString(), this.data.body).subscribe(response => {
      if (response) {
        this.showResendButton = false;
        this.startTimer();
        this.body = response;
      }
    });
  }

  resenMoiOtp = () => {
    this.sadadService.createMOIPayment(this.data.viewModel.biller.billerCode, this.data.viewModel.biller.serviceCode, this.data.body).subscribe(response => {
      if (response) {
        this.showResendButton = false;
        this.startTimer();
        this.body = response;
      }
    });
  }

  confirmSadadPayment(pin: string) {
    this.sadadService.confirmSadadPayment(this.body.sadadPaymentId, pin).subscribe(res => {
      if (res) {
        this.activeModal.close(res);
      }
    });
  }

  confirmSadadMOIPayment(pin: string) {
    this.sadadService.confirmMOIPayment(this.body.moiPaymentId, { otp: pin }).subscribe(res => {
      if (res) {
        this.activeModal.close(res);
      }
    });
  }

}
