import { fadeInAnimation } from './../../../shared/data/router-animation/router-animation';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import * as feather from 'feather-icons';
import { LayoutService } from '../../services/layout.service';
import { NavService } from '../../services/nav.service';
import { RouterOutlet } from '@angular/router';
import { SidebarComponent } from '../../components/sidebar/sidebar.component';
import { HeaderComponent } from '../../components/header/header.component';
import { NgClass, AsyncPipe } from '@angular/common';
import { DirectionalityService } from '@shared/services/directionality.service';
import { Direction } from '@angular/cdk/bidi';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss'],
    animations: [fadeInAnimation],
    standalone: true,
    imports: [NgClass, HeaderComponent, SidebarComponent, RouterOutlet, AsyncPipe]
})
export class ContentComponent implements OnInit, AfterViewInit {
  constructor(
    public navServices: NavService,
    public layout: LayoutService,
    public directionalityService: DirectionalityService,
  ) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      feather.replace();
    });
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  ngOnInit() {
  }

}
