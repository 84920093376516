import { HttpClient } from "@angular/common/http";
import { Injectable, signal } from "@angular/core";
import * as Oidc from "oidc-client";
import { User, UserManager, UserManagerSettings } from "oidc-client";
import { BehaviorSubject } from "rxjs";
import { GrowthBookService } from "src/app/core/services/growthbook.service";
import { NavService } from "src/app/core/services/nav.service";
import { SuppliersService } from "./suppliers.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private _authNavStatusSource = new BehaviorSubject<boolean>(false);
  authNavStatus$ = this._authNavStatusSource.asObservable();
  private manager = new UserManager(getClientSettings());
  private user: User | null;
  private userProfile: UserProfile | null;
  userProfile$ = new BehaviorSubject<UserProfile | null>(null);
  
  get _user(): User {
    return this.user;
  }

  get _userProfile(): UserProfile {
    return this.userProfile;
  }

  public deviceID = signal<string>("");

  constructor(
    private suppliersService: SuppliersService,
    private _http: HttpClient,
    private _gbs: GrowthBookService,
    private navService: NavService
  ) {
    this.manager.clearStaleState();
    this.manager.events.addSilentRenewError(() => {
      this.manager.signinSilent().catch(() => {
        this.signOut()
      });
    });
    // this.setUser();
  }

  isAuthenticated(): boolean {
    return this.user != null && !this.user.expired;
  }

  signIn() {
    this.manager.signinRedirect();
  }

  signOut() {
    this.manager.signoutRedirect();
  }

  async silentRenew() {
    const config = {
      userStore: new Oidc.WebStorageStateStore({
        store: window.sessionStorage,
      }),
    };
    try {
      this.user = await new Oidc.UserManager(config).signinSilentCallback();
    } catch (error) {
      console.error(error);
    }
  }

  setUser() {
    this.manager.getUser().then((user) => {
      localStorage.setItem("user", JSON.stringify(user));
      this.user = user;
      this._authNavStatusSource.next(this.isAuthenticated());
    });
  }

  async setUserFromStorage(user: User): Promise<User> {
    this.user = user;
    await this._gbs.loadGrowthBook(user.profile.email);
    return new Promise<User>((resolve, reject) => {
      resolve(user);
    });
  }

  async handleSignInCallBack() {
    try {
      this.user = await this.manager.signinRedirectCallback();
      this.setUserClaimsPermissionsAndMenu(this.user);
    } catch (error) {
      console.error(error);
    }
  }

  async setUserClaimsPermissionsAndMenu(user: User): Promise<void> {
    this.userProfile = user;
    await this._gbs.loadGrowthBook(user.profile.email);
    const permissions = await this.getPermissions();
    const isSupervisor = this.findSupplierSupervisorRole(user?.profile["role"]);

    this.setUserInformation(
      permissions,
      isSupervisor,
      user.profile.role,
      user.profile.EntityID
    );
    this._authNavStatusSource.next(this.isAuthenticated());
  }

  async getPermissions() {
    const response = await this.suppliersService
      .GetSupervisorsPermissions()
      .toPromise();
    return response?.permissions?.join(",");
  }

  findSupplierSupervisorRole(role: string | string[]): string {
    let isSupervisor =
      (typeof role === "string" && role === "SupplierSupervisor") ||
      (Array.isArray(role) && role.includes("SupplierSupervisor"));
    let isGodUser =
      (typeof role === "string" && role === "SupplierGodUser") ||
      (Array.isArray(role) && role.includes("SupplierGodUser"));

    if (isGodUser) return "false";
    if (isSupervisor) return "true";
    return null;
  }
  // Handle the case where user refreshes and permissions are loaded after the route guard is activated
  async setUserClaimsPermissionsAndMenuPromise(user: User): Promise<void> {
    this.userProfile = this.user;

    return new Promise<void>(async (resolve, reject) => {
      try {
        const permissions = await this.getPermissions();
        const isSupervisor = this.findSupplierSupervisorRole(
          user.profile["role"]
        );
        this.setUserInformation(
          permissions,
          isSupervisor,
          user.profile.role,
          user.profile.EntityID
        );
        this._authNavStatusSource.next(this.isAuthenticated());
        resolve();
      } catch (e) {
        this.signOut();
        reject(e);
      }
    });
  }

  setUserRoles(roles, isSupervisor): string[] {
    // console.log(isSupervisor, typeof (isSupervisor));
    if (isSupervisor === "false") return ["god_user"];
    else if (!isSupervisor) {
      this.signOut();
    }
    let userRoles: string[] = [];
    if (roles) {
      if (typeof roles === "string") userRoles.push(...roles.split('"'));
      userRoles.push(...roles);
    } else userRoles = roles;
    return userRoles;
  }

  setUserInformation(permissions, isSupervisor, roles, entityId) {
    console.log("setting userinfo");
    this.userProfile.userRoles = this.setUserRoles(roles, isSupervisor) || [];
    this.userProfile.userPermissions = this.setUserPermissions(
      permissions,
      isSupervisor
    );
    this.userProfile.entityId = entityId;
    this.setUserMenu(
      this.userProfile.userPermissions,
      this.userProfile.userRoles
    );
    this.userProfile.GodUser = isSupervisor === "false";
    this.userProfile$.next(this.userProfile);
    this.setDeviceUUID();
  }

  setDeviceUUID() {
    let deviceUUID = localStorage.getItem("deviceUUID");
    if (!deviceUUID) {
      deviceUUID = window.crypto.randomUUID();
      localStorage.setItem("deviceUUID", deviceUUID);
    }
    this.deviceID.set(deviceUUID);
  }

  setUserMenu(permissions, roles) {
    // console.log("setting user menu", permissions, roles);

    this.navService.setUserMenu(roles, permissions);
  }

  setUserPermissions(permissions, isSupervisor): string[] {
    let userPermissions: string[] = [];
    if (permissions) {
      if (typeof permissions === "string")
        userPermissions.push(...permissions.split(","));
    }
    return userPermissions;
  }
}

export enum PermissionsEnum {
  NO_PERMISSIONS_FOUND = 1,
  API_ERROR,
}
export class UserProfile extends User {
  userRoles?: string[];
  userPermissions?: string[];
  entityId?: number;
  GodUser?: boolean;
}

export function getClientSettings(): UserManagerSettings {
  return {
    authority: window["env"]["IS_BASE_URL"] || "https://accounts-stg.hala.com",
    client_id: "halalah_suppliers_portal",
    redirect_uri: `${window.origin}/auth-callback`,
    post_logout_redirect_uri: window.origin,
    response_type: "id_token token",
    scope: "openid profile roles scope_payments_gw_api scope_user_gw_api scope_web_users_gw_api",
    loadUserInfo: true,
    accessTokenExpiringNotificationTime: 60,
    automaticSilentRenew: true,
    silent_redirect_uri: `${window.origin}/auth-callback`,
  };
}
