import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'onboardingTranslate',
    standalone: true
})
export class OnboardingTranslatePipe implements PipeTransform {

    private translations: { [key: string]: string } = {};

    constructor() {
        this.loadTranslations();
    }

    private loadTranslations() {
        const enTranslations = require('../i18n/en.json');
        this.translations['en'] = enTranslations;

        const arTranslations = require('../i18n/ar.json');
        this.translations['ar'] = arTranslations;
    }

    transform(value: string, targetLanguage: string): string {
        if (!value) return '';

        const translations = this.translations[targetLanguage];
        if (!translations) {
            console.warn(`Translations for ${targetLanguage} not found`);
            return value;
        }

        const translatedValue = translations[value];
        if (!translatedValue) {
            console.warn(`Translation not found for key '${value}' in ${targetLanguage}`);
            return value;
        }

        return translatedValue;
    }
}
