// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: typeof window['env']['isProduction'] !== 'boolean' ? true : window['env']['isProduction'],
  baseAPIUrl: window['env']['API_URL'] || 'https://api-stg.hala.com/suppliers',
  bnplOnboardingBaseUrl: window['env']['BNPL_ONBOARDING_BASE_URL'] || 'https://api-stg.hala.com/bnpl-onboarding/api/v1/',
  // usersGatewayBaseURL: window['env']['USERSGATEWAY_BASEURL'] || 'https://api-stg.hala.com/web/userEntity',
  // gatewayBaseURL: window['env']['GATEWAY_BASEURL'] || 'https://api-stg.hala.com',
  loginBaseURL: window['env']['IS_BASE_URL'] || 'https://accounts-stg.hala.com',
  growthbookAPIKey: window['env']['GROWTHBOOK_API_KEY'] || 'sdk-fnEcLTezWmGouRay',
  payLaterOrderBaseUrl: window['env']['PAY_LATER_ORDERS_BASE_URL'] || "https://api-stg.hala.com/pay-later-orders/api/v1/",
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
