import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { LocalStorageManager } from '../../../shared/utils/local-storage-manager';

export interface LanguageSelection {
  Id: 'en' | 'ar' | 'ur';
  Name: string;
}

@Component({
  selector: 'app-language-switcher',
  standalone: true,
  imports: [
    CommonModule, FormsModule, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem, NgSelectModule, TranslocoPipe
  ],
  templateUrl: './language-switcher.component.html',
  styleUrl: './language-switcher.component.scss',
})
export class LanguageSwitcherComponent implements OnInit {

  selectedLanguage: string = '';
  languages: LanguageSelection[] = [
    {
      Id: 'en',
      Name: "English"
    },
    {
      Id: 'ar',
      Name: "العربية"
    },
    {
      Id: 'ur',
      Name: "اردو"
    }
  ];

  translocoService = inject(TranslocoService);
  localManager = inject(LocalStorageManager);

  ngOnInit(): void {
    this.selectedLanguage = this.localManager.getData('selectedLanguage') || 'en';
  }

  changeLanguage(event: any): void {
    const lang = event.Id;
    this.selectedLanguage = lang;
    this.localManager.saveData('selectedLanguage', this.selectedLanguage);
    this.translocoService.setActiveLang(lang);
  }
}
