import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { byPassAuthorization } from '../shared/interceptors/token.interceptor';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MerchantManagerOnboardingService {


  private _bnplOnboardingBaseURL = `${environment.bnplOnboardingBaseUrl}`;

  constructor(private http: HttpClient) {
    
  }

  makeHeaders(token){
    let header = new HttpHeaders().set('Authorization', `Bearer ${token}`).set('Content-Type', 'application/json')

    return {
      headers: header,
      context: byPassAuthorization()
    }
  }

  getOnboardingDetails(id:string,token:any,otp:number): Observable<any>{
    let req = "BnplRequest/" + id;
    return this.http.post(`${this._bnplOnboardingBaseURL}${req}`, {"otp":otp} ,this.makeHeaders(token));
  }

  updateOnboardingRequest(id:string,status:string,token:any,cashierID:number): Observable<any>{
    let req = `Cashier/${cashierID}/record/${id}`;
    return this.http.put(`${this._bnplOnboardingBaseURL}${req}`,{status:status}, this.makeHeaders(token));
  }

  sendOTP(id: string,token:string): Observable<any> {
    let req = `BnplRequest/${id}/sendOTP`; 
    return this.http.post(`${this._bnplOnboardingBaseURL}${req}`,{},this.makeHeaders(token));
  }

  // verifyMobileOTP(body: object): Observable<string> {
  //   return this.http.post(`${this.registrationBaseUrl}/VerifyMobileOTP`, JSON.stringify(body), { headers: this.header, context: byPassAuthorization(), responseType: 'text' });
  // }

}
