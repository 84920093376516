import { GOVERNMENT_SERVICES, bills } from './sadad.data';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { BillersResponseDto, BillDetails, MOIFieldsResponseDto, MOIFeeInquiryResponseDto, MoiRefundResponseDTO, Balance, Transaction, EntityProfile, BillDetailsDynamic } from './sadad.models';


@Injectable({
    providedIn: 'root',
})
export class SadadService {
  private _sadadBaseUrl = `${environment.baseAPIUrl}/user/sadad/v1`;
  private _sadadBaseUrlV2 = `${environment.baseAPIUrl}/user/sadad/v2`;
  private _sadadMOIBaseUrl = `${environment.baseAPIUrl}/user/moi/v1`;
  constructor(private _httpClient: HttpClient) { }

  // SADAD PROFILE

  getSadadProfile(params: any): Observable<EntityProfile> {
    return this._httpClient.get<EntityProfile>(`${environment.baseAPIUrl}/user/sadad/v2/accounts/postpaid`, {params});
  }

  addNewBillingAccount(body: any) {
    return this._httpClient.post(`${this._sadadBaseUrl}/profile`, body)
  }

  deleteBillingAccount(accountNumber: string) {
    return this._httpClient.delete(`${this._sadadBaseUrl}/profile/${accountNumber}`)
  }

  updateBillingAccount(accountNumber: string, body: any) {
    return this._httpClient.put(`${this._sadadBaseUrl}/profile/${accountNumber}`, body)
  }


  // SADAD BILLERS

  getSadadBillers(): Observable<BillersResponseDto> {
    return this._httpClient.get<BillersResponseDto>(`${this._sadadBaseUrlV2}/billers`)
  }

  getBillDetails(billerCode: string, accountNumber: string, paymentType:string): Observable<BillDetails> {
    return this._httpClient.get<BillDetails>(`${this._sadadBaseUrlV2}/${billerCode}/${accountNumber}?paymentTypeCode=${paymentType}`);
  }

  getBillDetailsDynamic(billerCode: string, accountNumber: string, paymentType:string): Observable<BillDetailsDynamic> {
    return this._httpClient.get<BillDetailsDynamic>(`${this._sadadBaseUrlV2}/${billerCode}/${accountNumber}?paymentTypeCode=${paymentType}`);
  }

  getUserBalance(): Observable<any> {
    return this._httpClient.get<any>(`${environment.baseAPIUrl}/web/userentity/suppliers/v1/balance`);
  }

  createSadadPayment(billerCode: string, accountNumber: string, body: any) {
    return this._httpClient.post(`${this._sadadBaseUrl}/${billerCode}/${accountNumber}`, body);
  }

  confirmSadadPayment(sadadPaymentId: string, otp: string) {
    return this._httpClient.post(`${this._sadadBaseUrl}/${sadadPaymentId}/confirm`, { otp });
  }

  payBulk(body: any): Observable<any> {
    return this._httpClient.post(`${this._sadadBaseUrl}/bulk`, body)
  }

  // MOI

  getMOIServices(): Observable<BillersResponseDto> {
    return this._httpClient.get<BillersResponseDto>(`${this._sadadMOIBaseUrl}/services`)
  }

  getMOIServiceDetails(billerCode: string, serviceCode: string): Observable<MOIFieldsResponseDto> {
    return this._httpClient.get<MOIFieldsResponseDto>(`${this._sadadMOIBaseUrl}/${billerCode}/${serviceCode}`)
  }

  getMOIServiceFees(billerCode: string, serviceCode: string, params: any): Observable<MOIFeeInquiryResponseDto | any> {
    return this._httpClient.get<MOIFeeInquiryResponseDto>(`${this._sadadMOIBaseUrl}/${billerCode}/${serviceCode}/fees`, {params})
  }

  createMOIPayment(billerCode: string, serviceCode: string, body: any): Observable<any> {
    return this._httpClient.post<any>(`${this._sadadMOIBaseUrl}/${billerCode}/${serviceCode}/fees`, body)
  }

  confirmMOIPayment(moiPaymentID: string, body: any) {
    return this._httpClient.post(`${this._sadadMOIBaseUrl}/${moiPaymentID}/confirm`, body)
  }

  refundMOIPayment(billerCode: string, serviceCode: string, body: any): Observable<MoiRefundResponseDTO> {
    return this._httpClient.post<MoiRefundResponseDTO>(`${this._sadadMOIBaseUrl}/${billerCode}/${serviceCode}/refund`, body)
  }

}
