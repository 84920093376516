import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { State } from '@store/app.state';
import { selectRegistration } from '@store/selectors';
import { LanguageSwitcherComponent } from "../../core/components/language-switcher/language-switcher.component";
import { DirectionalityService } from '@shared/services/directionality.service';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    standalone: true,
    imports: [RouterOutlet, LanguageSwitcherComponent, AsyncPipe]
})
export class LayoutComponent implements OnInit {

  constructor(private _store: Store<State>, private _router: Router, public directionalityService: DirectionalityService) {
    _store.pipe(
      select(selectRegistration)
    ).subscribe(state => {
      if (!state.cr) {
        const url = window.location.href;
        if (!url.toLowerCase().endsWith('cr-number')) {
          this._router.navigateByUrl('/registration/cr-number');
        }
      }
    });
  }

  ngOnInit(): void {
  }

}
