import { Routes } from '@angular/router';
import { full } from '@shared/routes/full.routes';
import { content } from '@shared/routes/routes';
import { ContentComponent } from './core/layout/content/content.component';
import { FullComponent } from './core/layout/full/full.component';
import { authGuard } from './core/services/auth-guard.service';
import { ManagerOnboardingDetailsComponent } from './merchant-manager-onboarding/manager-onboarding-details/manager-onboarding-details.component';
import { LayoutComponent as RegistrationLayout } from './registration/layout/layout.component';
import { registrationRoutes } from './registration/registration-routes';
import { tokenResolver } from './registration/token-resolver';

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: ContentComponent,
    canActivate: [authGuard],
    children: content
  },
  {
    path: '',
    component: FullComponent,
    children: full
  },
  {
    path: 'registration',
    component: RegistrationLayout,
    resolve: {
      token: tokenResolver
    },
    children: registrationRoutes
  },
  {
    path: 'merchant-manager-onboarding/:id',
    component: ManagerOnboardingDetailsComponent,
    resolve: {
      token: tokenResolver
    }
    // children: registrationRoutes
  },
  {
    path: '**',
    loadComponent: () => import('./pages/error/error404/error404.component').then((m) => m.Error404Component),
  }
];
