import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { provideTransloco } from '@jsverse/transloco';
import { StoreModule } from '@ngrx/store';
import { HttpErrorInterceptor, jwtInterceptor, spinnerInterceptor } from '@shared/interceptors';
import { Language } from '@shared/types/language';
import { reducers } from '@store/reducers';
import { provideLottieOptions } from 'ngx-lottie';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { TranslocoHttpLoader } from './../transloco-loader';
import { appRoutes } from './app.routes';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(appRoutes),
        provideAnimations(),
        importProvidersFrom(
            StoreModule.forRoot(reducers),
            ToastrModule.forRoot(),
            NgxSpinnerModule
        ),
        provideLottieOptions({
            player: () => import('lottie-web')
        }),
        provideEnvironmentNgxMask(),
        provideHttpClient(
            withInterceptorsFromDi(),
            withInterceptors([spinnerInterceptor, jwtInterceptor])
        ),
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        provideHttpClient(),
        provideTransloco({
            config: {
                availableLangs: ['en', 'ar', 'ur'],
                defaultLang: defaultLanguage(),
                // Remove this option if your application doesn't support changing language in runtime.
                reRenderOnLangChange: true,
                prodMode: !isDevMode(),
            },
            loader: TranslocoHttpLoader
        })
    ]
};

function defaultLanguage(): Language {
    try {
        return JSON.parse(localStorage.getItem('selectedLanguage')) ?? 'en';
    }
    catch (e) {
        return 'en';
    }
}
