import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'appPhoneNumber',
  standalone: true,
})
export class PhoneNumberPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    return `(+966) ${value.slice(0, 2)} ${value.slice(2, 5)} ${value.slice(5)}`;
  }

}
