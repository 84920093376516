import {
  AuthService,
  getClientSettings,
} from "./../../shared/services/auth.service";
import { inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateFn,
} from "@angular/router";
import { User } from "oidc-client";

// CanActivateFn
// Path: src/app/core/services/auth-guard.service.ts

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router = inject(Router);
  const authService = inject(AuthService);

  if (authService.isAuthenticated()) {
    return true;
  }
  return new Promise<boolean>((resolve, reject) => {
    const clientSettings = getClientSettings();
    const key = `oidc.user:${clientSettings.authority}:${clientSettings.client_id}`;
    const jwtToken = sessionStorage.getItem(key);
    if (jwtToken) {
      const userFromToken = User.fromStorageString(jwtToken);
      // expired cases
      if (userFromToken.expired) {
        localStorage.setItem("redirect_url", state.url);
        authService.signIn();
        resolve(false);
      }
      // end expired cases
      authService.setUserFromStorage(userFromToken).then((user) => {
        return authService
          .setUserClaimsPermissionsAndMenuPromise(user)
          .then((permissions) => {
            resolve(true);
          })
          .catch((error) => {
            // router.navigate(['../error-page/error-401'], {queryParams: {error}});
            // reject();
            resolve(true);
          });
      });
    } else {
      localStorage.setItem("redirect_url", state.url);
      authService.signIn();
      return false;
    }
  });
};
