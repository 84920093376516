import { UtilsService } from 'src/app/shared/services/utils.service';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-full-page-loader',
    templateUrl: './full-page-loader.component.html',
    styleUrls: ['./full-page-loader.component.scss'],
    standalone: true
})
export class FullPageLoaderComponent implements OnInit {
  public show: boolean = false;

  constructor(private utilsService: UtilsService) {
    this.utilsService.loading$.subscribe(value => {
      this.show = value;
    })
  }

  ngOnInit(): void {
  }

}
