import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageManager {

    saveData(key: string, data: any): void {
        this.localStorageSetItem(key, data);
    }

    deleteData(key: string): void {
        localStorage.removeItem(key);
    }

    getData(key: string): any {
        return this.localStorageGetItem(key);
    }

    private localStorageSetItem(key: string, data: any): void {
        localStorage.setItem(key, JSON.stringify(data));
    }

    private localStorageGetItem(key: string): any {
        return this.jsonTryParse(localStorage.getItem(key)!);
    }

    clearALL(): void {
        localStorage.clear()
    }

    jsonTryParse(value: string): any {
        try {
            return JSON.parse(value);
        }
        catch (e) {
            return null;
        }
    }
}
