import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';


// Menu
export class Menu {
	headTitle1?: string;
	headTitle2?: string;
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
	rolesAllowed: string[];
	permissionsAllowed: string[];
	enabled?: boolean = true;
	allowAll?: boolean;
	featureFlag?: string;
	translationKey?: string;
}
@Injectable({
	providedIn: 'root'
})
export class NavService implements OnDestroy {

	MENUITEMS: Menu[];
	menuItemsToShow = new BehaviorSubject<Menu[]>([]);
	constructor(private router: Router) {
		this.setMenuItems();
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			}
			if (evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if (window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}
	}

	private unsubscriber: Subject<any> = new Subject();
	public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search = false;

	// Language
	public language = false;

	// Mega Menu	
	public megaMenu = false;
	public levelMenu = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// Full screen
	public fullScreen = false;

	setMenuItems() {
		this.MENUITEMS = [
			{
				title: 'Dashboard',
				translationKey: "SIDEBAR_MENU.DASHBOARD",
				icon: 'home',
				type: 'link',
				active: false,
				allowAll: true,
				rolesAllowed: [],
				permissionsAllowed: [],
				path: '/dashboard',
			},
			{
				title: 'Beneficiaries',
				translationKey: "SIDEBAR_MENU.BENEFICIARIES",
				icon: 'user-plus',
				type: 'link',
				active: false,
				path: '/beneficiaries',
				rolesAllowed: [],
				permissionsAllowed: [
					"supplier_portal_beneficiaries_read",
					"supplier_portal_beneficiaries_write"
				]
			},
			{
				title: 'Sales groups',
				translationKey: "SIDEBAR_MENU.SALES_GROUPS",
				icon: 'dollar-sign',
				type: 'sub',
				active: false,
				permissionsAllowed: [
					"supplier_portal_salesperson_read",
					"supplier_portal_update_salesperson_write",
					"supplier_portal_create_salesmen_group_write",
					"supplier_portal_assign_salesmen_to_group_write"
				],
				rolesAllowed: [],
				children: [
					{
						path: '/sales-groups/create',
						title: 'Create sales group',
						translationKey: "SIDEBAR_MENU.CREATE_SALES_GROUP",
						icon: 'box',
						type: 'link',
						rolesAllowed: [],
						permissionsAllowed: [
							"supplier_portal_create_salesmen_group_write"
						]
					},
					{
						path: '/sales-groups/salesmen-management',
						title: 'Salesmen management',
						translationKey: "SIDEBAR_MENU.SALESMEN_MANAGEMENT",
						icon: 'box',
						type: 'link',
						rolesAllowed: [],
						permissionsAllowed: [
							"supplier_portal_assign_salesmen_to_group_write",
							"supplier_portal_salesperson_read",
							"supplier_portal_update_salesperson_write"
						]
					},
					{
						path: '/sales-groups/sales-groups-management',
						title: 'Sales groups management',
						translationKey: "SIDEBAR_MENU.SALES_GROUPS_MANAGEMENT",
						icon: 'box',
						type: 'link',
						rolesAllowed: [],
						permissionsAllowed: [
							"supplier_portal_create_salesmen_group_write",
							"supplier_portal_assign_salesmen_to_group_write"
						]
					}
				]
			},
			// {
			// 	title: 'External id management',
			// 	icon: 'dollar-sign',
			// 	type: 'link',
			// 	active: false,
			// 	permissionsAllowed: [],
			// 	rolesAllowed: [],
			// 	path: '/external-id-management'
			// },
			{
				title: 'Reports',
				translationKey: "SIDEBAR_MENU.REPORTS",
				icon: 'file',
				type: 'sub',
				active: false,
				permissionsAllowed: [
					"supplier_portal_transaction_read",
				],
				rolesAllowed: [],
				children: [
					{
						path: '/company-report',
						title: 'Statement',
						translationKey: "SIDEBAR_MENU.COMPANY_REPORT",
						icon: 'box',
						type: 'link',
						rolesAllowed: [],
						permissionsAllowed: [
							"supplier_portal_transaction_read"
						]
					},
					{
						path: '/company-report/salesmen',
						title: 'Salesmen Statement',
						translationKey: "SIDEBAR_MENU.SALESMEN_REPORT",
						icon: 'box',
						type: 'link',
						rolesAllowed: [],
						permissionsAllowed: [
							"supplier_portal_transaction_read"
						],
					},
				]
			},
			{
				path: '/tax-invoice',
				title: 'Tax Invoice',
				translationKey: "SIDEBAR_MENU.TAX_INVOICE",
				icon: 'file-text',
				type: 'link',
				rolesAllowed: [],
				permissionsAllowed: [
					'supplier_portal_transaction_read'
				],
			},
			{
				title: 'Account Statement',
				translationKey: "SIDEBAR_MENU.ACCOUNT_STATEMENT",
				icon: 'file-text',
				type: 'link',
				active: false,
				path: '/account-statement',
				rolesAllowed: [],
				permissionsAllowed: [
					"supplier_portal_transaction_read"
				]
			},
			{
				title: 'Users management',
				translationKey: "SIDEBAR_MENU.USERS_MANAGEMENT",
				icon: 'dollar-sign',
				type: 'sub',
				active: false,
				permissionsAllowed: [
					"supplier_portal_create_salesperson_write",
					"supplier_portal_create_supervisor_write",
					"supplier_portal_assign_supervisor_to_group_write",
					"supplier_portal_list_supervisor_read",
				],
				rolesAllowed: [],
				children: [
					{
						path: '/users-management/create-salesmen-accounts/',
						title: 'Create salesmen accounts',
						translationKey: "SIDEBAR_MENU.CREATE_SALESMEN_ACCOUNTS",
						icon: 'box',
						type: 'link',
						rolesAllowed: [],
						permissionsAllowed: [
							"supplier_portal_create_salesperson_write"
						]
					},
					{
						path: '/users-management/create-supervisor/',
						title: 'Create supervisor',
						translationKey: "SIDEBAR_MENU.CREATE_SUPERVISOR",
						icon: 'box',
						type: 'link',
						rolesAllowed: [],
						permissionsAllowed: ["supplier_portal_create_supervisor_write"]
					},
					{
						path: '/users-management/supervisors/',
						title: 'Supervisors',
						translationKey: "SIDEBAR_MENU.SUPERVISORS",
						icon: 'box',
						type: 'link',
						rolesAllowed: [],
						permissionsAllowed: ["supplier_portal_create_supervisor_write", "supplier_portal_list_supervisor_read", "supplier_portal_supervisor_permission_write"]
					}
				]
			},
			{
				title: 'Payment',
				translationKey: "SIDEBAR_MENU.PAYMENT",
				icon: 'slack',
				type: 'sub',
				active: false,
				rolesAllowed: [],
				permissionsAllowed: [
					"supplier_portal_create_cash_out_request_write",
					"supplier_portal_list_cash_out_request_read",
					"supplier_portal_view_cash_out_request_read",
					"supplier_portal_approve_cash_out_request_write",
					"supplier_portal_withdraw_cash_out_request_write",
					"supplier_portal_reject_cash_out_request_write",
					"supplier_portal_s2s_transfer_request_write"
				],
				children: [
					{
						path: '/cashout-requests/create',
						title: 'IBAN Transfer Request',
						translationKey: "SIDEBAR_MENU.IBAN_TRANSFER_REQUEST",
						icon: 'box',
						type: 'link',
						rolesAllowed: [],
						permissionsAllowed: [
							"supplier_portal_create_cash_out_request_write"
						]
					},
					{
						path: '/cashout-requests/',
						title: 'IBAN Requests',
						translationKey: "SIDEBAR_MENU.IBAN_REQUESTS",
						icon: 'box',
						type: 'link',
						rolesAllowed: [],
						permissionsAllowed: [
							"supplier_portal_list_cash_out_request_read",
							"supplier_portal_view_cash_out_request_read",
							"supplier_portal_approve_cash_out_request_write",
							"supplier_portal_withdraw_cash_out_request_write",
							"supplier_portal_reject_cash_out_request_write",
						]
					},
					{
						path: '/s2s-requests/',
						title: 'Requests',
						translationKey: "SIDEBAR_MENU.REQUESTS",
						icon: 'box',
						type: 'link',
						rolesAllowed: [],
						permissionsAllowed: [
							"supplier_portal_s2s_transfer_request_write"
						],
						featureFlag: 's2s'
					},
					{
						path: '/cashout-requests/settings/',
						title: 'Request Setings',
						translationKey: "SIDEBAR_MENU.REQUEST_SETTINGS",
						icon: 'box',
						type: 'link',
						rolesAllowed: [],
						permissionsAllowed: []
					},
					{
						path: '/sadad',
						title: 'SADAD',
						translationKey: "SIDEBAR_MENU.SADAD",
						icon: 'box',
						type: 'link',
						rolesAllowed: [],
						permissionsAllowed: [],
						featureFlag: 'supplier_sadad'
					}
				]
			},
		];

		function addGodUser(arr) {
			arr.forEach((item: Menu) => {
				if (item.allowAll)
					return;
				item.rolesAllowed.push("god_user");
				if (item.children)
					addGodUser(item.children)
			});
		}

		addGodUser(this.MENUITEMS);
		// this.menuItemsToShow.next(this.MENUITEMS);

	}

	setUserMenu(roles: any, permissions: any): Menu[] {
		const userMenu: Menu[] = this.getMenuItems(this.MENUITEMS, roles, permissions);

		this.menuItemsToShow.next(userMenu);

		return userMenu;
	}

	getMenuItems(items: Menu[] = this.MENUITEMS, userRoles, userPermissions): Menu[] {
		const menuItems: Menu[] = [];
		items.forEach(item => {
			const itemToAdd = { ...item };
			delete itemToAdd.children;
			if (this.checkIfMenuItemAllowed(item, userRoles, userPermissions)) {
				if (item.children) {
					itemToAdd.children = this.getMenuItems(item.children, userRoles, userPermissions);
				}
				menuItems.push(itemToAdd);
			}
		});
		return menuItems;
	}

	checkIfMenuItemAllowed(item: Menu, userRoles, userPermissions): boolean {
		if (!item.rolesAllowed.length && !item.permissionsAllowed.length) return true;
		if (item.rolesAllowed.length) {
			const filteredArray = userRoles.filter(value => item.rolesAllowed.includes(value));
			if (filteredArray.length) return true;
		}
		if (item.permissionsAllowed.length) {
			const filteredArray = userPermissions.filter(value => item.permissionsAllowed.includes(value));
			if (filteredArray.length) return true;
		}
		return false;
	}

	// Array


	ngOnDestroy() {
		this.unsubscriber.next(1);
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}
}
