import { Direction } from '@angular/cdk/bidi';
import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { FullPageLoaderComponent } from '@shared/components/full-page-loader/full-page-loader.component';
import { LoaderComponent } from '@shared/components/loader/loader.component';
import { TapToTopComponent } from '@shared/components/tap-to-top/tap-to-top.component';
import { DirectionalityService } from '@shared/services/directionality.service';
import { LocalStorageManager } from '@shared/utils/local-storage-manager';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    LoaderComponent,
    TapToTopComponent,
    FullPageLoaderComponent,
    RouterOutlet,
    NgxSpinnerModule,
    TranslocoPipe,
    AsyncPipe
  ]
})
export class AppComponent implements OnInit {

  direction!: Direction;

  constructor(
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private localManager: LocalStorageManager,
    private toastr: ToastrService,
    private translocoService: TranslocoService,
    public directionalityService: DirectionalityService,
    private destroyRef: DestroyRef
  ) { }

  ngOnInit(): void {
    const appTitle = this.titleService.getTitle();
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data.title) {
            return `${appTitle} - ${child.snapshot.data.title}`;
          }
          return appTitle;
        })
      )
      .subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });

    this.handleDirection();
  }

  handleDirection(): void {
    this.direction = this.localManager.getData('dir') || 'ltr';
    this.directionalityService.dirSubject.next(this.direction);

    this.translocoService.langChanges$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event) => {
        if (event == 'ar' || event == 'ur') {
          this.direction = 'rtl';
        }
        else {
          this.direction = 'ltr';
        }
        this.localManager.saveData('dir', this.direction);
        this.directionalityService.dirSubject.next(this.direction);
        this.configureToasterClassesByDirection();
      });
  }

  private configureToasterClassesByDirection(): void {
    let isRtl = this.direction == 'rtl';
    if (isRtl) {
      this.toastr.toastrConfig.positionClass = 'toast-top-left';
      this.toastr.toastrConfig.messageClass = 'toast-rtl';
      this.toastr.toastrConfig.toastClass = 'ngx-toastr toast-icon-rtl';
    }
    else {
      this.toastr.toastrConfig.positionClass = 'toast-top-right';
      this.toastr.toastrConfig.messageClass = 'toast-ltr';
      this.toastr.toastrConfig.toastClass = 'ngx-toastr toast-icon-ltr';
    }
  }
}
