import { createAction, props } from '@ngrx/store';
import { KycInfo } from '@shared/models/registration-models';

export const reset = createAction('[Registration] Reset');
export const updateCcToken = createAction('[Auth] UpdateCcToken', props<{ ccToken: any }>());
export const updateCr = createAction('[Registration] UpdateCr', props<{ cr: string }>());
export const updateId = createAction('[Registration] UpdateId', props<{ id: string }>());
export const updateMobile = createAction('[Registration] UpdateMobile', props<{ mobile: string }>());
export const updateDob = createAction('[Registration] UpdateDob', props<{ dob: string }>());
export const updateMainCategory = createAction('[Registration] UpdateCategoryType', props<{ category: string }>());
export const updateSubCategory = createAction('[Registration] UpdateCategory', props<{ subCategory: string }>());
export const updateMonthlyInTrans = createAction('[Registration] MonthlyInTrans', props<{ monthlyInTrans: string }>());
export const updateMonthlyOutTrans = createAction('[Registration] MonthlyOutTrans', props<{ monthlyOutTrans: string }>());
export const updateNumberOfBranches = createAction('[Registration] NumberOfBranches', props<{ numberOfBranches: string }>());
export const updateNumberOfEmployees = createAction('[Registration] NumberOfEmployees', props<{ numberOfEmployees: string }>());
export const updatePurpose = createAction('[Registration] UpdatePurpose', props<{ purpose: string }>());
export const updateYearlyRevenue = createAction('[Registration] YearlyRevenue', props<{ yearlyRevenue: string }>());
export const updateEmail = createAction('[Registration] UpdateEmail', props<{ email: string }>());
export const updateRegistrationHash = createAction('[Registration] UpdateRegistrationHash', props<{ hash: number }>());
export const updateSalesPerson = createAction('[Registration] UpdateSalesPerson', props<{
  salesPerson: {
    id: string,
    mobile: string,
    dob: string,
    externalId: string,
    language: number
  }
}>());
export const updateAttachments = createAction('[Registration] UpdateAttachments', props<{
  attachments: {
    crNumber: string,
    companyCR: string,
    companyAOA: string,
    managerID: string,
    delegationLetter: string,
    companyOwnersIDs: string[]
  }
}>());

export const updateKyc = createAction('[Registration] UpdateAttachments', props<{
  kycInfo: KycInfo
}>());
