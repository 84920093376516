import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderAndErrorService {
  private loadingSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loader$ = this.loadingSub$.asObservable();

  private errorSub$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  error$ = this.errorSub$.asObservable();
  /**
   * Contains in-progress loading requests
   */
  loadingMap: Map<string, boolean> = new Map<string, boolean>();

  constructor() { }
  setLoaderState(loading: boolean): void {
    this.loadingSub$.next(loading);
  }

  setError(error: string): void {
    this.errorSub$.next(error);
  }
}