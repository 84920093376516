import { Direction } from '@angular/cdk/bidi';
import { AuthService } from '../../shared/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { DirectionalityService } from '@shared/services/directionality.service';
import { LocalStorageManager } from '@shared/utils/local-storage-manager';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss'],
    standalone: true,
    imports: [TranslocoPipe]
})
export class MaintenanceComponent implements OnInit {

  error: boolean;
  direction!: Direction;

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute, private directionalityService: DirectionalityService, private localManager: LocalStorageManager,) { }

  async ngOnInit() {
    // check for error

    this.direction = this.localManager.getData('dir') || 'ltr';
    this.directionalityService.dirSubject.next(this.direction);

    if (this.route.snapshot.fragment.indexOf('error') >= 0) {
      this.error = true;
      return;
    }

    if (this.router.url.split('#')[0] === '/silent-callback')
      this.authService.silentRenew();
    else {
      await this.authService.handleSignInCallBack();
      this.router.navigate(['/dashboard']);
    }
  }

}
