<div [dir]="this.directionalityService.dirSubject | async">
    <div id="layout-wrapper">

        <div class="d-flex justify-content-between flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom box-shadow">
            <img src="assets/images/logo.svg" width="100" height="32" class="d-inline-block align-top">
            <app-language-switcher class="col-2"/>
              </div>
    
        <div class="main-content">
            <div class="container-fluid">
                <router-outlet></router-outlet>
            </div>
        </div>
    
        <footer class="footer">
    
        </footer>
    </div>
</div>

