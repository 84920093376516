import { tap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { updateCcToken } from '@store/actions/registration.actions';
import { State } from '../../store/app.state';
import { byPassAuthorization } from '../interceptors/token.interceptor';

@Injectable({
  providedIn: 'root'
})
export class CcTokenService {

  private _apiUrl = environment.loginBaseURL;

  constructor(private http: HttpClient,
    private _store: Store<State>) {

  }

  getCcToken(): Observable<string> {
    const body = new URLSearchParams();
    body.set('grant_type', 'client_credentials');
    body.set('client_id', 'ios_client_merchant');
    body.set('client_secret', 'HSL3jdfk60$LJS');
    body.set('scope', 'scope_user_gw_api scope_lookup_gw_api scope_payments_gw_api');
    return this.http.post<string>(`${this._apiUrl}/connect/token`, body, { context: byPassAuthorization(), headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') })
      .pipe(
        tap(response => {
          this._store.dispatch(updateCcToken({ ccToken: response['access_token'] }))
        }),
        map(response => response['access_token'])
      )
  }

  storeToken(token: string) {
    this._store.dispatch(updateCcToken({ ccToken: token }))
  }

}
